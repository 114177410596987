    .deliveryStatusImg {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .iconSize{
        font-size: var(--icons-size) !important;
    }
    .backgroundColorBlue {
        color: var(--color-base)
    }
    .deliveryStatusText {
        font-style: normal;
        font-weight: 600;
        font-size: var(--common-heading);
        line-height: 20.5px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--icons-text-color) !important;
    }
    .serviceContainer {
      display: flex;
      flex-direction: column;
      margin-top: 18px; 
      width: 67px; 
      margin-right: 4px;
    }
    .serviceItemsBox{
      line-height: 16px;
      display: flex;
      flex-direction: column;
    }
    .constructionRoundedIcon{
      color: var(--color-yellow);
      line-height: 16px;
      margin: 10px 2px 4px 13px;
    }
    .backgroundColorYellow{
      color:  var(--color-yellow) !important;
    }
    @media (min-width: 768px) {
        .deliveryStatusWidth {
          width: 120px;
        }
    }
      
      @media (max-width: 767px) {
        .deliveryStatusWidth {
          width: 70px;
        }
    }