.content{
    font-weight: 600 !important;
    font-size: var(--common-heading) !important;
    color: var(--color-dimgray) !important;
    font-style: normal;
  }

  .scopedClassColorText2 {
    font-style: normal;
    font-weight: 600 !important;
    font-size: var(--common-heading) !important;
    color: var(--color-dimmergray) !important;
    margin-left: 5px;
    }

    .PatientsAuth {
      box-shadow: none !important;
    }

    .DialogTitle {
      background-color: #f2f2f2;
      padding: 10px 20px;
      font-size: var(--secondary-heading) !important;
      font-weight: 700!important;
      color: black;
    }
    .headingText {
      color: var(--color-black) !important;
      font-weight: 600 !important;
      font-size: var(--details-heading);
      margin-bottom: 10px;
    }
    .spacedList {
      list-style-position: outside;
      padding-inline-start: 20px;
      margin-top: 0px;
    }
    .spacedList li {
      margin-bottom: 10px;
    }

    .spacingTop {
      margin-top: 20px;
    }

    .spacingRemoveTop {
      margin-top: 0px;
    }

    .labelText {
      color: var(--color-black) !important;
      font-weight: 600 !important;
    }

    .customNoButton {
      background-color: var(--color-darkgray-500) !important;
    }

    .customYesButton {
      background-color: var(--color-base) !important;
    }

    .asterisk {
      color: red;
      margin-left: 1px; 
    }
