@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");

body {
  margin: 0;
  line-height: normal;
}

:root {
  /* font-family */
  --base-text: "Nunito Sans";

  /* Colors */
  --bg: #7ccbec;
  --bg-white: #fff;
  --color-disableField: #eee;
  --color-black: #000;
  --color-base: #25a8e0;
  --color-dimgray: #808080;
  --color-dimmergray: #524242;
  --color-whitesmoke-100: #f5f5f5;
  --chrome-light-background: #e7eaed;
  --color-darkgray-100: #979797;
  --text-input-color: #717171;
  --color-gray:#96969640;
  --color-disabled:#f2f2f2;
  --color-disabled-text:rgba(0,0,0,0.66);
  --color-darkgray-500: #555555;
  --color-skyblue: #8cceea;
  --common-content-color:#757575;
  --color-silver-100: #c4c4c4;
  --color-darkgray-100: #b0b0b0;
  --color-darkgray-600: rgba(151, 151, 151);
  --color-darkgray-200: rgba(151, 151, 151, 0.8);
  --color-darkgray-300: rgba(151, 151, 151, 0.5);
  --color-darkgray-400: rgba(151, 151, 151, 0);
  --color-deepskyblue-100: rgba(37, 168, 224, 0.7);
  --color-aliceblue-100: #f0f8ff;
  --color-darkblue-500: #0b4b68;
  --color-lightred-100: #FF7E7A;
  --color-lightred-50: #E89896;
  --color-table-head: #E5E5E5;
  --color-table-head-title: #171C26;
  --table-hide-show-column-btn: #1976d20a;


 /* Status Colors */
 --color-yellow:  #FAC300;
 --color-orange: #FF7300;
 --color-red: #D2322D;
 --color-green: #619633;
 --color-grey: #777777;
 --color-lightgrey: #b6b6b6;
 --color-lightgreen: #92D02F;
 --border-shadow: #888888;
 --border-card: 1px solid rgba(0, 0, 0, .12);
 --color--darkgrey: #545454;


  /* Paddings */
  --padding-0px: 0;
  --padding-5px: 5px;
  --padding-6px: 6px;
  --padding-8px: 8px;
  --padding-10px: 10px;
  --padding-15px: 15px;
  --padding-16px: 16px;
  --padding-20px: 20px;
  --padding-30px: 30px;

  /* font sizes */
  --copyright-text:11px;
  --badges-font-size:13px;
  --common-content:14px;
  --common-heading:16px;
  --content-font-size-16px:16px;
  --secondary-heading:20px;
  --details-heading:24px;
  --main-heading:30px;

  /* border radiuses */
  --br-2px: 2px;
  --br-5px: 5px;
  --br-6px:6px;
  --br-8px: 8px; 
  --br-10px: 10px; 
  --br-30px: 30px;

/* Gaps */
  --gap-10px: 10px;
  --gap-15px: 15px;
  --gap-20px: 20px;
  

  /* Icons Font Size */
  --icons-size: 40px;
  --icons-text-color: var(--color-dimgray); 
  --icon-size-time: var(--details-heading);

  /* font-weight */
  --fontWeight600 : 600;
  --fontWeight700 : 700;
  --fontWeight800 : 800;

}


body,button,input,h1,h2,h3,h4,h5,h6,p,span,label,optgroup,select,textarea,li,a,div{
  font-family: var(--base-text) !important;
}
