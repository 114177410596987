.NotificationList {

  .innerIcon {
    width: 35px;
    height: 35px;
  }

  .mainHeadingText {
    font-size: var(--main-heading);
    font-weight: 600;
  }

  .notificationTitle {
    font-weight: 'bold';
    font-size: var(--common-heading);
    color: var(--color-black)
  }

  .topHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .markAllRead {
    font-size: var(--common-heading);
    color: var(--color-base);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
  }

  .noticationsListing {
    overflow: auto; 
    box-shadow: none;
    border-radius: 4px;
    padding-bottom: 0;
    cursor: pointer;
  }

  .notificationListingLanding {
    margin-top: 10px;
    border: 1px solid var(--color-darkgray-300);
  }

  .notificationListingCard {
    border-radius: 0px;
    border: none;
  }

  .feedBackLink {
    color: var(--color-base) !important;
    text-decoration: underline !important;
    cursor: pointer;
    margin-left: auto;
  }

  .notificationListLayout{
    display: flex;
  }

  .icon {
    margin: 4px 0px;
    height: 60px;
    width: 60px;
    background: var(--color-base);
  }

  .title {
    font-size: var(--secondary-heading);
    color: var(--color-black)
  }

  .time {
    font-size: var(--common-heading);
    font-weight: 600;
    color: var(--color-dimgray)
  }

  .message {
    font-size: var(--common-heading);
    color: var(--color-dimmergray);
    font-weight: 600;
    margin: 0;
    word-wrap: break-word;
  }

  .entityType{
    max-width: fit-content;
  }

  .toggleBtnText{
    font-size: var(--common-heading);
    font-weight: 600;
    color: var(--color-dimgray);
    margin-left: 10px;
   } 

  .subHeading{
    font-size: var(--secondary-heading);
    font-weight: 700;
    color: var(--color-dimmergray);
    margin-top: 20px;
   } 
   
   .landingPageBox{
    border: 1px solid var(--color-silver-100);
   }

  @media (max-width: 600px) {
    .topHeader {
      flex-direction: column;
      align-items: flex-start;
    }
    .notificationListLayout{
      display: block;
    }

  }
}