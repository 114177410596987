    .flexContainer {
      display: flex ;
      align-items: center ;
      justify-content: space-between ;
      padding-right: 53px ;
      background: rgba(151, 151, 151, 0.25);
      .myStyle {
        width: 100%;
        color: var(--color-black);
        font-weight: 600;
        font-size: var(--secondary-heading);
      }
    }

    .dimensions {
      text-align: center;
    }
    .wordWrapContact {
      word-wrap: break-word;
      max-width: 300px;
      font-size: var(--common-heading);
      color: var(--color-dimmergray);
      font-weight: 600;
      margin-left: 5px;
    }
    .customNotesStyle {
      display: flex ;
      margin-top: 9px ;
      font-size: var(--content-font-size-16px) ;
    }

    .customStyles {
      display: flex ;
      align-items: center ;
      margin-top: 9px ;
      font-size: var(--content-font-size-16px) ;
    }

    .textStyles {
      color: var(--color-dimgray) ;
      font-family: var(--base-text) ;
      font-weight: 700 ;
    }
    .customText4 {
      color: var(--color-dimgray);
      font-size: var(--common-heading) ;
      font-weight: 600 ;
    }

    .greenText {
      color: var(--color-green) ;
      font-weight: bold ;
      margin-left: 8px ;
    }

    .greenTextMargin {
      color: var(--color-green) ;
      margin-left: 5px ;
      margin-top: 5px ;
    }

    .redText {
      color: var(--color-red) ;
      font-weight: bold ;
      margin-left: 8px ;
    }

    .redTextMargin {
      color: var(--color-red) ;
      margin-left: 5px ;
    }

    .dimGrayText {
      color: var(--color-dimgray) ;
    }

    .iconDimensions {
      width: 26px ;
      height: 26px ;
      padding-bottom: 3px ;
      padding-right: 3px ;
    }

  .alignment{
    .absolutePositionLeft {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      color: var(--color-deepskyblue-100);
    }
    .absolutePositionRight {
      position: absolute;
      top: 50%;
      right: 0%;
      transform: translateY(-50%);
      background: transparent;
      color: var(--color-deepskyblue-100);
    }
    .arrowIcon{
      font-size: var(--icons-size) ;
    }
  }
    .absolutePosition:hover {
      background: var(--bg-white) ;
    }
    .paddingStyle {
      padding: 20px 35px;
    }
    .imageDimensions {
      max-width: 300px;
      max-height: 400px;
    }
    .fontSizeLarge {
      font-size: var(--secondary-heading);
    }
    .textStyle {
      font-size: var(--secondary-heading);
      margin-top: 3px;
    }
    .marginLeftAuto {
      margin-left: auto;
      .customButtonStyle {
        background-color: var(--color-base);
        color: white ;
        text-transform: none ;
      }
  
      .customButtonStyle:hover {
        background-color: var(--color-base) ;
      }
    }  
    .flexContainerProofOfDelivery {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-end;
    }
    @media screen and (max-width: 500px) {
      .imageDimensions {
        max-width: 265px;
        max-height: 400px;
      } 
      .alignment{
        .absolutePositionLeft {
          right: 86%;
        }
        .absolutePositionRight {
          left: 88%;
        }
      }
      .flexButton {
        flex-basis: 100%;
      }
    }