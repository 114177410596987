.MapLocationButton{
.mapLocation{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.mapButton {
      min-width: 48px;
      height: 48px;
      width: 48px;
      border-radius: 8px;
      margin-left: 8px;
      background-color: var(--color-base);
      color:  var(--bg-white);
      font-size: var(--details-heading);
      font-weight: 400;
      &:hover {
            background-color: var(--color-base);
      }
}

@media (max-width: 480px) {
    .mapLocation{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
}
.mapIcon{
    color: var(--bg-white);
    font-size: 30px;
}

}

.mapLocationTitle {
    font-size: var(--badges-font-size);
}