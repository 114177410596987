.CustomButtons {
    height: 42px;
    width: 100px;

    .buttonBase {
        width: 100%;
        height: 100%;
        text-transform: capitalize;
    }

    .customNoButton {
        @extend .buttonBase;
        background-color: var(--color-darkgray-500) !important;
    }

    .customYesButton {
        @extend .buttonBase;
        background-color: var(--color-base) !important;
    }
}