.GenericAccordionCard {

    .accordianBottomTop10px {
        margin: 10px 0px;
        justify-content: space-between;
        align-items: center;
      }

      .searchContainer1Style {
        position: "relative";
      }
      .customGenericAccordion {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 30px;
        box-shadow: none;
      }
      .customGenericAccordion .MuiAccordionSummary-content {
        margin: 0;
      }
      .customGenericAccordionSummary {
        z-index: 1;
        position: relative;
        cursor: default;
        min-height: 40px;
      }
      .customGenericAccordionDetails {
        z-index: 1;
        position: absolute;
        width: 100%;
        background-color: var(--bg-white);
        border-radius: 10px;
        box-shadow: 0px 1px 5px #888888;
        height: auto;
        max-height: 400px;
        overflow-y: auto;
      }
      .customButtonStyleReset {
        background-color: var(--color-darkgray-500);
        color: var(--bg-white);
        text-transform: none;
        margin-right: 10px;
      }
      .customButtonStyleReset:hover {
        background-color: var(--color-darkgray-500);
      }
      .customButtonStyleSearch {
        background-color: var(--color-base);
        color: white;
        text-transform: none;
        margin-right: 10px;
      }
      .DisabledButton {
        color: #FFF !important;
        background-color: var(--color-skyblue) !important;
    }
      .customButtonStyleSearch:hover {
        background-color: var(--color-base);
      }
      .customResetButton{
        justify-content :space-between;
        align-items: center;
        margin-top: 20px;
      }
      .buttonResponsive {
        width: 130px;
        height: 38px;
      }
      @media (max-width: 600px) {
        .buttonResponsive {
          width: 50px;
          height: 30px;
        }
    }
}