.breadcrumbColor{
    color: var(--color-black) !important ;
    cursor: default;
}
.breadcrumbColorUnderLine{   
    cursor: pointer;
}
.breadcrumPosition{
    display: flex; 
}

.breadcrumbLabel{
    color: var(--color-black) !important ;
}