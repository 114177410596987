.CommonNotes {
    .MarginTop{
        width: 100%;
        margin-top: 15px;
    }
    .notesStyles{
        font-weight: 600;
        margin-top: 5px;
    }
    .boxShadow{
        box-shadow: none; 
    }
}