.mainContainer {
  border: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
  border-radius: 4px;
  border-bottom: none;
}
.WatchLaterIcon{
  font-size: 40px !important;
  margin-top: 5px !important;
}
.backgroundColorYellow{
  color:  var(--color-yellow) !important;
}
.orderTypeContainer {
  margin-top: 4px !important; 
  margin-left: 15px !important; 
}
.marginClass {
  margin: 0;
  font-size: var(--details-heading) !important;
  font-weight: 600;
}

.marginNurse {
  margin-top: 35px !important;
}

.myCustomChip1 {
  margin: 10px;
  border-radius: 6px !important;
  font-size: var(--badges-font-size) !important;
  font-weight: 800 !important;
  margin-left: 0px !important;
  background-color: var(--color-orange) !important;
  color: white !important;
}

.redButton {
  min-width: 48px !important;
  height: 48px !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
  background-color: var(--color-red) !important;
  color: var(--bg-white) !important;
  cursor: pointer;
  border: none; 
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
  &:hover {
    box-shadow:
      rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
  }
}
.customLink {
  margin-left: 8px;
  color: var(--color-base) !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.blueButton {
  min-width: 48px !important;
  height: 48px !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
  background-color: var(--color-grey) !important;
  color: var(--bg-white) !important;
  cursor: pointer;
  border: none; 
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
  &:hover {
    box-shadow:
      rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
  }
}

.customSizeImage {
  height: 40px !important;
  min-width: 27px !important;
}

.receiptIcon {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.clipAlignment {
  display: flex;
  flex-wrap: wrap;
  font-size: var(--common-heading) !important;
}

.customTextLabel {
  color: var(--color-dimgray);
  font-size: var(--common-heading) !important;
  font-weight: 600 !important;
}

.customText {
  color: var(--color-dimmergray);
  font-size: var(--common-heading) !important;
  font-weight: 600 !important;
  margin-left: 5px;
}

.customText5 {
  color: var(--color-darkgray-500);
  font-size: var(--content-font-size-16px) !important;
  font-weight: 600 !important;
}

.customButton3 {
  min-width: 48px !important;
  height: 48px !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
  background-color: or !important;
  color: var(--bg-white) !important;
}

.branchText {
  color: var(--color-dimgray) !important;
  font-size: var(--secondary-heading) !important;
  font-weight: 800 !important;
}

.receiptButton {
  min-width: 48px !important;
  height: 48px !important;
  border-radius: 10px !important;
  padding: 5px !important;
  background-color: var(--color-base) !important;
  color: var(--bg-white) !important;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
  &:hover {
    box-shadow:
      rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
  }
}

.gridAlignment {
  display: flex !important;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .iconContainer {
    margin-top: 0px;
    justify-content: flex-start;
  }

  .gridAlignment {
    margin-top: 5px;
    display: flex !important;
    justify-content: flex-start;
  }

  .marginNurse {
    margin-top: -9px !important;
  }
}

@media (max-width: 480px) {
  .marginClass {
    font-size: var(--details-heading) !important;
  }
 
    .iconContainer{
      display: flex;
      align-items: flex-start !important;
      justify-content: flex-start !important;   
      margin-top: 10px !important;
      margin-left: 0px !important;
    }
    .containerClass{
      display: flex;
      align-items: flex-start !important;
      justify-content: flex-start !important;
      margin-top: 5px !important;
    }
    .mobileView{
      display: flex;
      flex-direction: row !important;
      align-items: flex-start;
      margin-left: 0px;
      margin-top: 15px;
      
    }
  .marginNurse {
    margin-top: -9px !important;
  }
  .statusButtons {
    flex-direction: column !important;
    align-items:flex-start !important;
    margin:4px 0px !important;
}
}
.containerClass{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; 
  margin-bottom: 15px !important;
}

.chipEtaFlex{
  display: flex;
  
  .chip{
    color: var(--bg-white);
    border-radius: 6px;
    font-weight: 800;
    font-size: var(--badges-font-size);
  }
}

.maplocation{
  margin: 0 10px;
}

.statusButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px 0px;
}

.chipWithEtaAndMap {
  display: flex;
  justify-content: space-between !important;
}
