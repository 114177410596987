.progressBar {
  width: 100%;
  height: 15px;
  background-color: #ccc;
  border-radius: 5px;
  margin-bottom: 8px;
  margin-top: 4px;
}

.progress {
  height: 100%;
  background-color:var(--color-base);
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.label {
  margin-right: 10px;
}