.DialogContent {

    margin: 15px 15px 0px 15px;
    padding-bottom: 0px !important;
  }
  .dialogContainer{
    display: flex;
    justify-content: space-between;
  }

  .linkContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
  }

  .prescriptionFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .DialogData {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px dashed;
    border-radius: 8px;
    overflow: initial !important;
  }
  .titleName{
    color: var( --color-black) !important;
    font-size: var(--secondary-heading);
  }
  .alertHeading{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .title {
    background-color: #F2F2F2;
    color: var(--color-base) !important;
    padding: 10px 20px !important;
    align-items: center;
    justify-content: space-between;
    font-size: var(--secondary-heading);
    font-weight: 700 !important;
  }
  .viewAndHide{
    cursor: pointer; 
    color: var(--color-base) !important;
  }
  .fieldFlex {
    display: flex;
    height: 70px;
    flex-wrap: wrap;
    align-content: center;
  }
  .documentContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-size: var(--common-heading);
  }
  .alertContainer{
    font-weight: 600;
    max-width: 100% !important;
    width: 100% !important;
    margin-bottom: 20px; 
    margin-top: 10px;
  }
.iconDiv {
    text-align: end !important;
}

.gridClassMain {
    display: flex;
    flex-wrap: wrap;
}

.content {
    word-wrap: break-word !important;
    font-weight: 700 !important;
    font-size: var(--common-heading);
}
.fileSizeColor{
    font-size: var(--common-heading);
    color: var(--color-dimmergray);
}
.sizeColor{
    font-size: var(--common-heading);
    color: var(--color-dimgray);
}
.gridClass {
    margin: 0px 10px 10px 0px;
    width: 287px;
    padding: 0px 10px 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border: 1px solid var(--color-darkgray-100);
    font-size: var(--common-content);
}

.cloudContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
}

.instructionsList {
    color: var(--color-dimgray);
    font-size: var(--common-heading);
    font-weight: 600;
}

.actionButton {
    padding: 0 35px !important;
    margin-top: 14px !important;
}

.progressBar {
    padding: 0px 35px 0px 35px !important;
    margin-top: 14px !important;
}

.errorText {
    margin-top: 5px;
    color: var(--color-red);
    font-size: var(--common-content);
}
.customButtonBase {
    text-transform: none !important;
    background-color: var(--color-base) !important;
    color: #FFF !important;
    height: 35px;
    width: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 10px 15px;
    margin-top: 10px;
    cursor: pointer !important;
    font-size: var(--common-content) !important;

    &:hover {
        background-color: var(--color-base);
    }
}

.DialogButton {
    @extend .customButtonBase;
    background-color: var(--color-base) !important;
}

.DialogButtonUpload {
    @extend .customButtonBase;
    background-color: var(--color-base) !important;
    padding: 20px !important;
    margin-bottom: 10px !important;
}
.DialogButtonCancel {
    @extend .customButtonBase;
    background-color: var(--color-darkgray-500) !important;
    padding: 20px !important;
    margin-bottom: 10px !important;

    &:hover {
        background-color: var(--color-darkgray-500) !important;
    }
}

.ImageUploadDescription {
    margin: 0px !important;
    font-size: 16px;
    font-weight: 700;
}

.DisabledButton {
    @extend .customButtonBase;
    color: #FFF;
    background-color: var(--color-skyblue) !important;
    padding: 20px !important;
    margin-left: 20px !important;
    margin-bottom: 10px !important;
}

.DisabledButton:hover {
    @extend .customButtonBase;
    background-color: #979797;
    padding: 20px !important;
    margin-left: 20px !important;
    margin-bottom: 10px !important;
}

.progressWidth {
    width: 85%;
}
.progressBarPos {
    width: 10%;
    margin-left: 3px;
    margin-bottom: 2px;
}

@media (max-width: 480px) {
    .ImageUploadDescription {
        display: none;
    }

    .MuiPaper-root {
        width: 100% !important;
        max-width: 100% !important;
    }
    .linkContainer{
        display: block;
        justify-content: space-between;
    }
    .gridClass {
        display: flex !important;
        margin: 0px 10px 15px 0px !important;
        width: auto !important;
        padding: 0px 10px 10px 10px !important;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border: 1px solid var(--color-darkgray-100);
        font-size: var(--common-content);
    }
}

@media screen and (max-width: 900px) {
    .cloudContainer {
        width: 100% !important;
    }

    .DialogData {
        display: block !important
    }

    .gridClass {
        display: flex !important;
        margin: 0px !important;
        flex-direction: column !important;
        width: auto !important;
        padding: 0px 10px 10px 10px !important;
        margin-bottom: 15px !important;
    }

    .gridClassMain {
        display: block !important;
    }
}
