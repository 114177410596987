.SilentTransfer {
    padding: 0px 10px 0px 0px;
    .customBox2 {
        text-align: left;
        margin-top: 20px;
        margin-bottom: 20px;
        .cardContainer {
            padding: 0px;
            border-radius: 8px;
        }
    }

    .scopedClassName {
        background: var(--color-whitesmoke-100);
        height: auto;
        font-family: var(--base-text);
        color: var(--color-black);
        font-weight: 600;
        display: flex;
        padding: 0px 15px 0px 0px;
        align-items: center;
        font-size: var(--secondary-heading);
    }

    .buttonContainer {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .personIcon {
        padding: 10px 10px 10px 18px;
    }

    .contactIcons {
        margin-top: 3px;
        display: inline-block;
        vertical-align: middle;
    }

    .zipCode {
        display: block;
    }
    .gridClass {
        border-bottom: var(--border-card);
        padding: 0 20px 20px 20px;
        margin-top: 10px;
    }

    .paddingTop20px {
        padding-top: 20px
    }

    .branchName {
        font-weight: 600;
        font-size: var(--common-heading);
        color: var(--color-dimmergray);
        font-style: normal;
    }

    .content {
        font-weight: 600;
        font-size: var(--common-heading);
        color: var(--color-dimgray);
        font-style: normal;
    }

    .inventoryIconStyle {
        width: 80%;
        height: 80%;
        margin-right: 30px;
    }

    .equipmentImageStyle {
        width: 80%;
        height: 80%;
        margin-right: 30px;
        margin-top: 15px;
    }

    .content2 {
        font-size: var(--content-font-size-16px);
        color: var(--color-dimmergray);
        font-weight: 600;
        margin-left: 8px;
    }

    .content3 {
        font-size: var(--content-font-size-16px);
        color: var(--color-dimmergray);
        font-weight: 600;
    }

    .tags {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .equipmentName {
        font-size: var(--secondary-heading);
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 0px
    }

    .chips {
        display: flex;
        align-items: center;
        margin-top: 4px;
    }

    .customLink {
        margin-left: 8px;
        color: var(--color-base);
        font-weight: 600;
        font-size: var(--common-heading);
        cursor: pointer;
    }

    .myCustomChip {
        color: var(--bg-white);
        margin: 0px 5px 10px 5px;
        padding: 0px;
        border-radius: 6px;
        font-size: var(--badges-font-size);
        font-weight: 800;
    }

    .chipColorDarkGrey{
        background-color: var(--color--darkgrey);
        display: flex;
    }

    .chipColorRed{
        background-color: var(--color-red);
        display: flex; 
    }
    
    .chipColorOrange{
        background-color: var(--color-orange);
        display: flex; 
    }

    .chipColorBase{
        background-color: var(--color-base);
        display: flex; 
    }

    .PreviousButton {
        text-transform: none;
        background-color: var(--color-darkgray-500);
        color: var(--bg-white);
        width: 135px;
        height: 42px;
        font-family: var(--base-text);
        font-weight: 400;
        padding-left: 0;
        cursor: pointer;
    }

    .PreviousButton:hover {
        background-color: var(--color-darkgray-500);
    }

    .container {
        display: flex;
        align-items: center;
        width: 90%;
        word-wrap: break-word;
        line-height: 22px;
    }

    .marginLeft {
        margin-left: 8px;
    }

    .marginTop {
        margin-top: 5px;
    }

    .smartPhoneStyling {
        color: var(--color-black);
        padding: 3px;
    }

    .marginTopBottom {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .colorRed {
        color: var(--color-red);
    }

    .DisabledButton:hover {
        background-color: var(--color-skyblue);
    }

    .AddButton {
        text-transform: none;
        background-color: var(--color-base);
        color: var(--bg-white);
        width: 135px;
        height: 42px;
        cursor: pointer;
    }

    .AddButton:hover {
        background-color: var(--color-base);
    }

    .AddButton:disabled {
        color: var(--bg-white);
        background-color: var(--color-skyblue);
    }

    .customStyle {
        display: flex;
        align-items: center;
        width: 90%;
        word-wrap: break-word;
        line-height: 22px;
        margin: 5px 0px;
    }

    .expandStyle {
        cursor: pointer;
        margin-left: auto;
    }

    .stylingHeading {
        font-size: var(--common-heading);
        font-weight: 600;
    }

    .noDataMarginTop{
        margin-top: 30px;
    }

    .wordWrap {
        word-wrap: break-word;
        padding-left: 19px;
        padding-bottom: 15px;
        line-height: 22px;
    }

    .divContainer {
        width: 90%;
        flex-direction: column;
    }

    @media (max-width: 480px) {
        .dataMargin {
            border-bottom: var(--border-card)
        }

        .tags {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
        .zipCode {
            display: block;
        }
    }
}