.customContentText {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 600;
    color: var(--color-dimmergray);
    font-size: var(--common-heading);
}
.customLabelText {
    color: var(--color-dimgray);
    font-size: var(--common-heading);
    font-weight: 600;
  }