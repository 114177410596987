.Demographics {
.customPatientLink {
        font-size: var(--details-heading);
        font-weight: 600;
        color: var(--color-base);
        display: block;
        text-decoration: none;
        cursor: pointer;
    }
    .customMargin {
        margin-bottom: 5px;
    }
}
