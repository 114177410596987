.customBox2 {
    text-align: left;
    margin-bottom: 30px;
  }
.paddingBotom5px{
  padding-bottom: 5px;
}
.routerLink{
  font-size: var(--content-font-size-16px);
  font-weight: var(--fontWeight600);
  color: var(--color-base);
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
}
.iconSize{
    font-size: var(--icons-size) !important;
}
  .customTypography {
    color: var(--color-base);
    
    font-size: 0.6rem;
  }
  .myCustomClass2 {
    margin: 10px;
    border-radius: 6px !important;
    font-size:  13px !important;
    margin-left: 5px !important;
    font-weight: 800;
  }
  .myCustomGreyChip {
    background-color: var(--color-grey) !important;
  }
  .myCustomChip {
    background-color: var(--color-green) !important; /* Active status */
    color: white !important;
  }
  .myCustomOtherStatusChip {
    background-color: var(--color-red) !important; /* other status */
    color: white !important;
  }
  .myCustomInactiveChip {
    background-color: gray !important; /* Inactive status */
    color: white !important;
  }
  .myCustomChip1 {
    margin: 10px;
    border-radius: 6px !important;
    font-size: 13px !important;
    font-weight: 800 !important;
    margin-left: 5px !important;
    background-color: var(--color-orange) !important;
    color: white !important;
  }
  .myCustomChip2 {
    margin: 10px;
    border-radius: 6px !important;
    font-size:  var(--common-heading);
    margin-left: 5px !important;
    background-color: var(--color-red) !important;
    color: white !important;
  }
  .marginClass {
    margin: 0;
    font-size: 24px !important;
    font-weight: 600;
  }
  .scopedClassNameLine {
    display: flex;
    align-items: center;
    margin-top: 1;
    font-weight: 600 !important;
    font-size: var(--common-heading) !important;
  }
  .calendarMonthIcon{
    display: flex;
    flex-Direction: column 
  }
  .branchText {
    color: var(--color-dimmergray) !important;
    font-size: var(--secondary-heading) !important;
    font-weight: 800 !important;
  }
  .customText5 {
    color: var(--color-darkgray-500);
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .scopedClassColorText {
    margin-left: 5px;
    color: var(--color-black);
    font-weight: 600 !important;
    font-size: var(--common-heading) !important;
  }
  .customTextBold1 {
    color: var(--color-dimmergray);
    font-size: var(--common-heading);
    font-weight: 600 !important;
    margin-left: 5px;
  }
  .customFlexContact {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: baseline;
}
.wordWrapContact {
  word-wrap: break-word;
  max-width: 100%;
  font-size: var(--common-heading);
  color: var(--color-dimmergray);
  font-weight: 600;
  margin-left: 5px;
}
.iconDimensions {
  width: 26px ;
  height: 26px;
  padding-bottom: 3px ;
  padding-right: 3px;
}
.customNotesStyle {
  display: flex ;
  margin-top: 9px ;
  font-size: var(--content-font-size-16px);
}
.flexCenter {
  display: flex !important;
  align-items: center !important;
  font-size: var(--content-font-size-16px);
}
.textStyle {
  color: var(--color-dimgray);
  font-family: var(--base-text);
  font-weight: 700;
}
.greenBoldText {
  color: var(--color-green) !important;
  font-weight: bold !important;
  margin-left: 8px !important;
}
.greenTextWithMargin {
  color: var(--color-green);
  margin-left: 5px;
  margin-top: 5px;
}
.redBoldText {
  color: var(--color-red) !important;
  font-weight: bold !important;
  margin-left: 8px !important;
}
.fontSizeLarge {
  font-size: var(--secondary-heading);
}
.textStyleProofOfDelivery {
  font-size: var(--secondary-heading);
  margin-top: 3px;
}
.marginLeftAuto {
  margin-left: auto;
} 
.dimGrayText {
  color: var(--color-dimgray);
  font-family: var(--base-text);
}
.imageContainer {
  max-width: 140px;
  height: 140px;
  cursor: pointer;
  object-fit: contain;
  margin-right: 15px;
  margin-bottom: 5px;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
  width: 100%;
}
.flexContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 20px;
}
.customButton {
  margin-left: 8px;
  background-color: var(--color-base);
  padding: 8px 19px;
  font-weight: 100;
  font-size: var(--content-font-size-16px);
  text-transform: none;
  font-family: var(--base-text);
}
.customText4 {
  color: var(--color-dimgray);
  font-size: var(--common-heading) !important;
  font-weight: 600 !important;
}
.tooltipStyling{
  font-size: 13px;
}
.redTextMargin {
  color: var(--color-red) ;
  margin-left: 5px ;
}

  .customLink {
    color: var(--color-base) !important;
    text-decoration: underline !important;
    cursor: pointer;
    margin-left: auto;
  }
.emr {
  margin: 10px;
  border-radius: 6px !important;
  font-size: var(--badges-font-size) !important;
  font-weight: 800 !important;
  margin-left: 0px !important;
  background-color: #545454 !important;
  color: #fff !important;
}
  .mobileText {
    color: var(--color-dimgray); 
  }
  .customText3 {
    display: flex;
    align-items: center;
    color: var(--color-dimmergray) !important;
    font-weight: 600;
    font-size: var(--common-heading) !important;
  }
  .customText6 {
    color: var(--color-dimmergray);
    font-size: var(--common-heading) !important;
    font-weight: 600 !important;
    margin-left: 5px;
  }
  .smallMargin {
    height: 15px !important;
    margin-left: 3px;
    margin-right: 3px;
  }
  .chipMargin {
    margin: 5px;
    background-color: rgba(151, 151, 151, 0.4) !important;
  }
  .clipAlignment {
    display: flex;
    flex-wrap: wrap;
    font-size: var(--common-heading) !important;
  }
  .marginTypography {
    margin-top: 8px
  }  
  .addressFontSize{
  font-size: var(--common-heading) !important;
}
.scopedClassHeading {
    color: var(--color-black);
    height: 50px !important;
    font-weight: 600 !important;
    font-size: var(--secondary-heading) !important;
  }
  .scopedClassName {
    background: var(--color-whitesmoke-100);
    height: 50px;
    padding: 10px !important;
    color: var(--color-dimgray);
    font-weight: 400;
    display: flex;
  }
  .divider {
    border-top: 1px solid #0d0c0c; 
    margin: 16px 0; 
  }
  .receiptIcon{
    display: flex;
      align-items: flex-end;
      justify-content: flex-end;
  }
  
  .receiptButton {
    min-width: 48px !important;
    height: 48px !important;
    border-radius: 10px !important;
    margin-left: 8px !important;
    padding: 5px !important;
    background-color: var(--color-base) !important;
    color: var(--bg-white) !important; 
    cursor: pointer !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
    &:hover {
      box-shadow:
        rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
    }
    }
  .customText1 {
    align-items: center;
    color: var(--color-dimgray);
    font-weight: 600 !important;
    font-size: var(--common-heading) !important;
  }
  .customMargin {
    margin: 10px;
    margin-left: 5px;
    border-radius: 2px;
  }
  .customDiv {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
        
.blueButton {
    min-width: 40px !important;
    height: 48px !important;
    width: 48px !important;
    border-radius: 10px !important;
    margin-left: 8px !important;
    background-color: var(--color-grey) !important;
    color:  var(--bg-white) !important;
    }
    
.redButton {
    min-width: 40px !important;
    height: 48px !important;
    width: 48px !important;
    border-radius: 10px !important;
    margin-left: 8px !important;
    background-color: var(--color-red) !important;
    color:  var(--bg-white) !important;
}

.customSizeImage {
    height: 40px !important;
    min-width: 27px !important;
}
.customButton3 {
  min-width: 48px !important;
  height: 48px !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
  background-color: or !important;
  color: var(--bg-white) !important;
}
.deliveryStatusImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.deliveryStatusImg1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.backgroundColorGreen {
  color: rgb(97, 150, 51) !important;
  }
  .backgroundColorYellow{
    color:  var(--color-yellow) !important;
  }

  .deliveryStatusText {
    font-style: normal;
    font-weight: 600;
    font-size: var(--common-heading);
    line-height: 20.5px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--icons-text-color) !important;
  }
  .flexContainerProofOfDelivery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
  }
.customText {
  font-family: var(--base-text);
  color: var(--color-dimgray) !important;
  font-size:  var(--common-heading)!important;
  font-weight: 800 !important;
  margin-top: 8px !important;
}
  .backgroundColorOrange {
    color: var(--color-orange) !important;
    }
  .gridAlignment{
    display: flex !important;
    align-items: center;
    justify-content: flex-end ;
  }
.iconContainer{
  display: flex;
    justify-content: flex-end;
    margin-top: -60px;
}
.customButtonStyle {
  background-color: var(--color-base) !important;
  color: white !important;
  text-transform: none !important;
}

.customButtonStyle:hover {
  background-color: var(--color-base) !important;
}
@media (max-width: 768px) { 
  .gridAlignment{
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: 5px;
  }
}
 
@media (max-width: 768px) { 
  .iconContainer{
      margin-top: 0px;
      justify-content: flex-start;
  }
  .gridAlignment{
    display: flex !important;
    justify-content: flex-start !important;
    margin-top: 5px;
  }
  .deviveryStatusText{
    margin-left: 0px
  }
  .marginNurse{
    margin-top: 0px !important;
  }
}
.backgroundColorBlue {
    color: var(--color-base)
    }
.customSizeImage2 {
  height: 40px;
  min-width: 40px;
}

.chipEta {
  display: flex;
}

.customDemographicsBox {
  display: flex;
  flex-direction: column;
}

@media (min-width: 600px) {
  .customDemographicsBox {
    flex-direction: row;
  }
}

.customDemographicsBoxItem {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (min-width: 600px) {
  .customDemographicsBoxItem {
    align-items: center;
    flex-direction: row;
  }
}

@media (max-width: 600px) {
  .chipEta {
    flex-direction: column;
  }
  .etaIcon {
    margin-left: 0px;
  }
  .receiptButton {
    margin-left: 0px !important;
  }
}
  
  @media (max-width: 480px) {
    .marginClass {
      font-size: 24px !important;
    }
  }
    @media (max-width: 479px) {
      .myCustomClass2 {
        margin-left: 0px;
      }
    }
  @media (min-width: 600px) {
    .customTypography {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 500px) {
    .flexButton {
      flex-basis: 100%;
    }
  }
  