.ShareFeedbackThankYouPage{
    .commonHeading{
        font-size: var(--main-heading);
        font-weight: 600;
        color: black;
    }
    .commonContent{
        font-size: var(--content-font-size-16px);
        color: black;
    }
    .contactLink {
        font-weight: bold;
        color: var(--color-black);
        text-decoration: underline;
        text-transform: lowercase;
    }
    .subHeading{
        font-size: var(--secondary-heading);
        font-weight: 600;
        color: var(--color-black);
    }
    .SelectButton {
        text-transform: none;
        background-color:  var(--color-base);
        color: var(--bg-white);
        line-height: 18px;
        height: 44px;
        width: 250px;
        align-items: center;
        border-radius: 4px;
        padding-left: 5px;
        font-family: var(--base-text);
        cursor: pointer;
        font-size: var(--common-heading);
        border: none; 
      }
}