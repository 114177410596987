// .dialogTitle {
//       background-color: #F2F2F2;
//       padding: 10px 18px !important;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       height: 60%;
// }

.dialogDescription {
      text-align: left;
      margin: 0px;
      font-size: var(--content-font-size-16px);
      word-break: break-word;
      font-weight: 400;
}

.iconContainer{
      flex-direction: row;
      flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  font-weight: bold;
  font-size: var(--secondary-heading);
}

.sessionTimeoutButton {
      background-color: #25A8E0 !important;
      min-width: 150px !important;
      min-height: 50px !important;
      margin-right: 15px !important;
      margin-bottom: 5px !important;
}

.customButtonBase {
      width: 100px;
      height: 38px;
      border: 0;
      font-size: var(--common-content);
      text-transform: capitalize !important;

      &:hover {
            background-color: var(--color-1);
      }
}

.customYesButton {
      @extend .customButtonBase;
      font-size: 14px !important;
      background-color: var(--color-base) !important;
}
.customCancelButton {
    @extend .customButtonBase;
    font-size: 14px !important;
    background-color: var(--color-darkgray-500) !important;
}
.commonDialogTitle {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background: rgba(242, 242, 242, 1);
}

.commonFexContainer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  font-weight: bold;
  font-size: var(--secondary-heading);
}