.columnLink {
    font-size: 13px;
    color: var(--color-base);
    display: block;
    text-decoration: underline;
    cursor: pointer;
}

.actionLink {
    display: flex;
    justify-content: space-between;
}

.rejectionContainer {
    margin: 2px 0;
}