.OrderFeedback {
    .commonHeading{
        font-size: var(--main-heading) !important;
        font-weight: 600 !important;
        color: var(--color-black) !important;
    }
    .feedbackHeading {
        font-size: var(--secondary-heading);
        font-weight: var(--fontWeight600);
        margin-top: 20px;
    }

    .marginTop10{
        margin-top: 10px;
    }
    .marginBottom10{
        margin-bottom: 10px;
    }
    .marginTop20{
        margin-top: 20px !important;
    }
    .borderRadius8{
        border-radius: var(--br-8px) !important;
    }
    .orderStatusNo {
        margin: 10px;
        border-radius: 5px;
        border: 1px solid var(--color-darkgray-100);
        cursor: pointer !important;
    }

    .orderStatusNo label span {
        text-align: center;
        padding: 12px 18px;
        display: block;
        color: var(--color-black);
        cursor: pointer;
    }

    .orderStatusNo label input {
        position: absolute;
        display: none;
    }

    .orderStatusNo input:checked+span {
        background: var(--color-red) !important;
        color: var(--bg-white);
    }

    .orderStatusNo:hover {
        background: var(--color-red) !important;
        span {
            color: var(--bg-white) !important;
        }
    }

    .orderStatusYes {
        margin: 10px;
        border-radius: 5px;
        border: 1px solid var(--color-darkgray-100);
        cursor: pointer !important;
    }

    .orderStatusYes label span {
        cursor: pointer;
        text-align: center;
        padding: 12px 18px;
        display: block;
        color: var(--color-black);
    }

    .orderStatusYes label input {
        position: absolute;
        display: none;
    }

    .orderStatusYes input:checked+span {
        background: var(--color-green) !important;
        color: var(--bg-white);
    }

    .orderStatusYes:hover {
        background: var(--color-green) !important;
        span {
            color: var(--bg-white) !important;
        }
    }

    .orderStatusYesBut {
        margin: 10px;
        border-radius: 5px;
        border: 1px solid var(--color-darkgray-100);
        cursor: pointer !important;
    }

    .orderStatusYesBut label span {
        cursor: pointer;
        text-align: center;
        padding: 12px 18px;
        display: block;
        color: var(--color-black);
    }

    .orderStatusYesBut label input {
        position: absolute;
        display: none;
    }

    .orderStatusYesBut input:checked+span {
        background: var(--color-orange) !important;
        color: var(--bg-white);
    }

    .orderStatusYesBut:hover {
        background: var(--color-orange) !important;
        span {
            color: var(--bg-white) !important;
        }
    }

    .ratingButton{
        margin: 5px;
    }

    .ratingButton1 {
        border-radius: var(--br-6px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-lightgrey);
        cursor: pointer !important;
    }

    .ratingButton1 label span {
        cursor: pointer;
        text-align: center;
        padding: 12px 20px;
        display: block;
        color: var(--color-black);
    }

    .ratingButton1 label input {
        position: absolute;
        display: none;
        
    }

    .ratingButton1 input:checked+span {
        border-radius: var(--br-6px);
        background: var(--color-red) !important;
        color: var(--bg-white);
    }

    .ratingButton1:hover {
        border-radius: var(--br-6px);
        background: var(--color-red) !important;
        span {
            color: var(--bg-white) !important;
        }
    }

    .ratingButton2 {
        border-radius: var(--br-6px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-lightgrey);
        cursor: pointer !important;
    }

    .ratingButton2 label span {
        cursor: pointer;
        text-align: center;
        padding: 12px 20px;
        display: block;
        color: var(--color-black);
    }

    .ratingButton2 label input {
        position: absolute;
        display: none;
        color: var(--color-dimgray);
    }

    .ratingButton2 input:checked+span {
        border-radius: var(--br-6px);
        background: var(--color-lightred-100) !important;
        color: var(--bg-white);
    }

    .ratingButton2:hover {
        border-radius: var(--br-6px);
        background: var(--color-lightred-100) !important;
        span {
            color: var(--bg-white) !important;
        }
    }

    .ratingButton3 {
        border-radius: var(--br-6px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-lightgrey);
        cursor: pointer !important;
    }

    .ratingButton3 label span {
        cursor: pointer;
        text-align: center;
        padding: 12px 20px;
        display: block;
        color: var(--color-black);
    }

    .ratingButton3 label input {
        position: absolute;
        display: none;
    }

    .ratingButton3 input:checked+span {
        border-radius: var(--br-6px);
        background: var(--color-yellow) !important;
        color: var(--bg-white);
    }

    .ratingButton3:hover {
        border-radius: var(--br-6px);
        background: var(--color-yellow) !important;
        span {
            color: var(--bg-white) !important;
        }
    }

    .ratingButton4 {
        border-radius: var(--br-6px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-lightgrey);
        cursor: pointer !important;
    }

    .ratingButton4 label span {
        cursor: pointer;
        text-align: center;
        padding: 12px 20px;
        display: block;
        color: var(--color-black);
    }

    .ratingButton4 label input {
        position: absolute;
        display: none;
    }

    .ratingButton4 input:checked+span {
        border-radius: var(--br-6px);
        background: var(--color-lightgreen) !important;
        color: var(--bg-white);
    }

    .ratingButton4:hover {
        border-radius: var(--br-6px);
        background: var(--color-lightgreen) !important;
        span {
            color: var(--bg-white) !important;
        }
    }

    .ratingButton5 {
        border-radius: var(--br-6px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-lightgrey);
        cursor: pointer !important;
    }

    .ratingButton5 label span {
        cursor: pointer;
        text-align: center;
        padding: 12px 20px;
        display: block;
        color: var(--color-black);
    }

    .ratingButton5 label input {
        position: absolute;
        display: none;
    }

    .ratingButton5 input:checked+span {
        border-radius: var(--br-6px);
        background: var(--color-green) !important;
        color: var(--bg-white);
    }

    .ratingButton5:hover {
        border-radius: var(--br-6px);
        background: var(--color-green) !important;
        span {
            color: var(--bg-white) !important;
        }
    }

    .ratingButtonNA {
        border-radius: var(--br-6px);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-lightgrey);
        cursor: pointer !important;
    }

    .ratingButtonNA label span {
        cursor: pointer;
        text-align: center;
        padding: 12px 20px;
        display: block;
        color: var(--color-black);
    }

    .ratingButtonNA label input {
        position: absolute;
        display: none;
    }

    .ratingButtonNA input:checked+span {
        border-radius: var(--br-6px);
        background: var(--color-dimgray) !important;
        color: var(--bg-white);
    }

    .ratingButtonNA:hover {
        border-radius: var(--br-6px);
        background: var(--color-dimgray) !important;
        span {
            color: var(--bg-white) !important;
        }
    }

    .DialogButtonCancel {
        background-color: var(--color-dimgray);
        text-transform: capitalize;
        &:hover {
            background-color: var(--color-dimgray);
        }
    }

    .dialogueButtonSubmit {
        background-color: var(--color-base);
        text-transform: capitalize;
        &:hover {
            background-color: var(--color-base);
        }
    }

    .actionButton {
        margin-top: 8px;
        padding: 0px;
        margin-bottom: 26px;
    }
    .checkboxPadding {
        padding: 9px 9px 9px 0
    }
    .lowRating{
        text-align: end;
        color: var(--color-dimgray);
    }
    .highRating{
        color: var(--color-dimgray);
    }
    .orderType{
        margin: 0;
        margin-right: 5px;
        color: var(--color-dimgray);
        text-transform: capitalize;
        font-weight: 600;
    }
    .orderName{
        margin: 0;
        color: var(--color-dimmergray);
        font-weight: 600;
    }
    .orderList{
        margin-top: 1rem;
    }
    .disableInputField {
        background-color: var(--color-disabled);
    }
    .disabledButton {
        text-transform: capitalize;
        color: var(--bg-white) !important;
        background-color: var(--color-skyblue) !important;
      }
    
      .disabledButton:hover {
        background-color: var(--color-darkgray-100);
      }
      .verticalAlignTop{
        vertical-align: top;
  }
      
    @media screen and (min-width:601px) and (max-width:769px) {
        .ratingButton{
            span{
                padding: 5px 16px !important;
            }
        }
    }
    @media (max-width:600px) {
        .ratingButton{
            span{
                padding: 5px 12px !important;
            }
        }
        .lowRating{
            order: 1;
            text-align: left;
        }
        .highRating{
            order: 2;
            text-align: right;
        }
    }
}