.customContainer {
  background-color: var(--bg-white);
  flex-grow: 1;
  overflow: auto;
}
.myCustomClassFont {
  width: 20px;
  height: 20px;
}
.iconContiner{
  display: flex !important;
  flex-direction: column !important;
  flex: 1;
}

@media (max-width: 480px) {
  .myCustomClassFont {
    margin-right: 10px;
  }
}

@media screen and (max-width: 820px){ 
.iconContiner{
  display: flex !important;
  flex-direction: column !important;
}
}