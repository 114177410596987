    .myCustomChip {
        color: white !important;
        margin: 0px 5px 10px 5px !important;
        padding: 2px !important;
        border-radius: 6px !important;
        font-weight: 800 !important;
        font-size: var(--badges-font-size) !important;
      } 
      .chips {
        display: flex;
        align-items: center;
        margin-top: 6px;
        flex-wrap: wrap;
      }
      @media (max-width: 480px) {
        .chips {
          margin-top: 0px !important;
        }
        .myCustomChip{
        margin: 0px 5px 10px 0px !important;
        }
      }