
.spaceBetween {
    margin-top: 10px;
  } 
.customSizeImage {
    height: 40px !important;
    min-width: 27px !important;
  }
.buttonText {
  text-transform: none !important;
  border-radius: 6px !important;
  font-size: var(--badges-font-size) !important;
  font-weight: 800 !important;
  box-shadow: none;
  color: #ffffff;
}
.deliveryStatusText {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 27.5px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--icons-text-color) !important;
}
.divCustom{
  position: absolute !important;
    right: 0px !important;
    bottom: -6px !important;
}

.mainContainer {
  border: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
  border-radius: 4px;
}
.receiptIcon {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.clipboardStyle {
  height: 30px !important;
  min-width: 27px !important;
}

.statusButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px 0px;
}

.proofOfDeliveryButton {
  border-radius: 10px !important;
  padding: 7px !important;
  background-color: var(--color-base) !important;
  height: 48px !important;
  min-width: 48px !important;
  color: var(--bg-white) !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
  &:hover {
    box-shadow:
      rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
  }
}
  
  @media screen and (min-width: 481px) {
    .gridBox {
      border-radius: 5px;
      margin-top: 30px;
      height: 100%;
      
    }
  }
  @media screen and (max-width: 480px) {
    .gridBox {
      border-radius: 5px;
      margin-top: 30px;
      height: 100%;
      margin-right: 10px;
      margin-left: 10px;
    }
    .receiptIcon {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      margin-bottom: 10px;
    }

    .statusButtons {
      flex-direction: column;
      align-items:flex-start;
      margin:4px 0px
    }
  }
  @media screen and (max-width: 480px){
    .gridBoxMargin {
        margin-bottom: 40px;
   }
  }
  .customBox {
  padding-right: 24px;
  display: flex;
  justify-content: flex-end;
  }
  .customContainer {
  background-color: var(--bg-white);
  flex-grow: 1;
  }
  .customContainerClass {
  
  margin-top: 16px;
  margin-bottom: 32px;
  
  @media (max-width: 1280px) {
    max-width: 1100px;
  }
  
  @media (max-width: 960px) {
    max-width: 800px;
  }
  
  @media (max-width: 600px) {
    max-width: 500px;
  }
  
  @media (max-width: 480px) {
    max-width: 90%;
   
  }
  
  @media (max-width: 320px) {
    max-width: 94%;
    
  }
  }
  .customDiv {
  cursor: pointer !important;
  display: flex;
  flex-direction: column;
  }
  .dropDown_Container {
  margin-top: 15px;
  width: 30%;
  margin-left: auto;
  margin-right: 2px;
  }
  .customDiv2 {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15%;
  }
  .customBoxStatus {
  width: 140px;
  height: 45px;
  padding: 7px 20px;
  border-radius: 5px;
  gap: 10px;
  }
  
  .customButton {
  background-color: var(--color-base) !important;
  box-shadow: none;
  text-transform: none;
  }
  
  .customButton:hover {
  background-color: var(--color-base) !important;
  }
  .customText {
  font-family: var(--base-text);
  color: var(--color-dimgray) !important;
  font-size: 1rem !important;
  font-weight: 800 !important;
  }
  .customBoxClass {
  color: red;
  font-family: var(--base-text);
  font-weight: 600 !important;
  }
  @media (max-width: 600px) {
  .customBoxClass {
    width: 100px;
    font-size: 0.8rem;
  }
  }
  @media (min-width: 601px) {
  .customBoxClass {
    width: 100px;
    font-size: 1rem;
  }
  }
  .customImage {
  height: 120px;
  width: 120px;
  margin-left: 8px;
  }
  .customIcon {
  margin-left: 5px;
  height: 24px;
  width: 53.5px;
  }
  .customButton1 {
  height: 40px;
  width: 40px;
  background-color: var(--color-base) !important;
  margin: 0 8px !important;
  }
  
  .customButton1:hover {
  background-color: var(--color-base) !important;
  }
  .customBox2 {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
  }
  .customBox3 {
  text-align: left;
  margin-top: 15px;
  }
  .scopedClassName {
  background: var(--color-darkgray-300);
  height: 38px;
  font-family: var(--base-text);
  color: var(--color-dimgray);
  font-weight: 400;
  display: flex;
  }
  .scopedClassHeading {
  font-family: var(--base-text);
  color: var(--color-black);
  margin-top: 8px !important;
  margin-left: 15px !important;
  font-weight: 600 !important;
  }
  .noteClassName {
    background: var(--color-darkgray-300);
    height: 70px;
    font-family: var(--base-text);
    color: var(--color-dimgray);
    font-weight: 400;
    display: flex;
    }
  
    .noteDate{
      margin-Left: '10px';
      font-size: "15px";
      padding-bottom: "5px";
      font-weight:"bold"
    }
  .noteClassHeading {
    font-family: var(--base-text);
    margin-top: 15px !important;
    margin-left: 15px !important;
    font-weight: 700 !important;
    font-size: 25px !important;
    color: var(--color-darkgray) !important;
    }
  
    
  .scopedClassLine {
  display: flex;
  align-items: center;
  
  & span {
    color: var(--color-black);
  }
  
  & span:first-of-type {
    color: var(--color-dimgray);
  }
  
  font-family: var(--base-text);
  }
  .scopedClassNameLine2 {
  align-items: center;
  margin-top: 1;
  font-weight: 600 !important;
  padding-bottom: 0px !important;
  
  & span:first-of-type {
    color: var(--color-dimgray);
  }
  
  font-family: var(--base-text);
  }
  
  .scopedClassNameLine {
  display: flex;
  align-items: center;
  margin-top: 1;
  font-weight: 600 !important;
  
  & span {
    color: var(--color-dimmergray);
  }
  
  & span:first-of-type {
    color: var(--color-dimgray);
  }
  
  font-family: var(--base-text);
  }
  .scopedClassColorText {
  margin-left: 5px;
  color: var(--color-dimmergray);
  font-weight: 600 !important;
  }  
  .customTypography {
  color: var(--color-base);
  font-family: var(--base-text);
  font-size: 0.6rem; 
  }
  
  .customTypography2 {
  color: black;
  font-family: var(--base-text);
  font-size: 0.6rem; 
  }
  
  @media (min-width: 600px) {
  .customTypography {
    font-size: 0.8rem; 
  }
  }
  .marginClass{
  margin: 0 
  }
  
  .customTextStyle {
  color: var(--color-darkgray-700);
  font-weight: 400;
  margin-left: 5px;
  }
  .chipMargin{
  margin: 5px;
  }
  .responsiveChip {
  white-space: nowrap; 
  }
  .customButton2 {
  min-width: 48px !important;
  height: 48px !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
  background-color: var(--color-base) !important;
  color:  var(--bg-white) !important;
  }
  
  .customButton3 {
  min-width: 48px !important;
  height: 48px !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
  background-color: var(--color-base) !important;
  color:  var(--bg-white) !important;
  }
  
  .greenButton {
  min-width: 48px !important;
  height: 48px !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
  background-color: #2e7d32 !important;
  color:  var(--bg-white) !important;
  }
  
  .grayButton {
  min-width: 48px !important;
  height: 50px !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
  background-color: rgb(140, 138, 138) !important;
  color:  var(--bg-white) !important;
  }
  
  .blueButton {
  min-width: 48px !important;
  height: 48px !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
  background-color: var(--color-base) !important;
  color:  var(--bg-white) !important;
  }
  
  .redButton {
  min-width: 48px !important;
  height: 50px !important;
  border-radius: 10px !important;
  margin-left: 8px !important;
  background-color: #D10000 !important;
  color:  var(--bg-white) !important;
  }
  .customSizeImage {
  height: 40px !important;
  min-width: 27px !important;
  }
  .customSizeImage1 {
  height: 27px;
  min-width: 27px;
  }
  
  .faMapLocation {
  color: #25a8e0;
  font-size: 30px;
  }
  
  .faMapLocation-mobile {
  padding-right: 245px;
  }
  
  .faMapLocation-desktop {
  padding-right: 100%;
  }
  
  .smallIcon {
  width: 20px;
  height: 20px;
  color:  var(--bg-white) !important;
  }
  
  .customBadge {
  margin: 3px !important;
  border-radius: 6px !important;
  background-color: #D22B2B !important;
  color:  var(--bg-white) !important;
  }
  
  .customText1 {
  display: flex;
  align-items: center;
  color: var(--color-dimgray);
  font-weight: 600 !important;
  font-size: 18px !important;
  }
  
  .customMargin {
  margin: 10px;
  margin-left: 5px;
  border-radius: 2px;
  }
  
  .customText3 {
  display: flex;
  align-items: center;
  color: var(--color-black);
  font-weight: 500;
  }
  
  .smallMargin {
  height: 14px !important;
  margin-left: 3px;
  margin-right: 3px;
  }
  
  .customText4 {
  color: var(--color-dimgray);
  font-size: 0.9rem;
  }
  
  .customText5 {
  color: var(--color-black);
  font-size: 0.9rem;
  }
  .marginTypography{
  margin-top: 8px
  }
  
  .marginTypography1{
  margin-top: 25px !important
  }
  
  .myCustomClass {
  color: var(--color-dimgray);
  font-size: 0.9rem;
  font-weight: 600;
  }
  .myCustomClass1 {
  color: var(--color-darkgray-700);
  margin-left: 5px;
  }

  .myCustomClass3 {
  margin: 5px 5px 5px 0px;
  border-radius: 6px !important;
  font-size:  var(--badges-font-size) !important;
  font-weight: 800 !important;
  padding: 0px !important;
  }
  
  .myCustomClassFont {
  width: 20px;
  height: 20px;
  }
    .myCustomClass2 {
      margin-left: 8px;
      border-radius: 6px !important;
      
      @media (max-width: 479px) {
        margin-left: 0px;
      }
    }
  .customButtonContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex: 1;
  }
  
  @media (max-width: 360px) {
  .customButtonContainer {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  }
  .clipAlignment {
   display: flex;
  flex-wrap: wrap;
  }
  
  @media screen and (max-width: 435px) {
  .chipSecond{
  margin-left: 52px !important;
  }
  }

.iconContainer{
  display: flex;
    justify-content: flex-end;
    margin-top: -60px;
}
@media (max-width: 768px) { 
  .iconContainer{
      margin-top: 15px;
      justify-content: flex-start;
  }
}
.content{
  font-weight: 600 !important;
  font-size: var(--common-heading) !important;
  color: var(--color-dimgray) !important;
  font-style: normal;
}

.scopedClassColorText2 {
  font-style: normal;
  font-weight: 600 !important;
  font-size: var(--common-heading) !important;
  color: var(--color-dimmergray) !important;
  margin-left: 5px;
  }

.dataMargin {
  margin-top: 50px;
  border-bottom:1px solid rgba(0,0,0,.12);
}