.reportItemContainer {
  padding: 0 16px;

  .mainHeadingText {
    font-size: var(--main-heading);
    font-weight: 600;
  }

  .filterContainer {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  .filter {
    width: 100%;
  }

  .pagination {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-size: var(--common-heading);
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    .filterContainer {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}