.IdgButton {
      .IdgButton {
            background-color: var(--color-base);
            border-radius: 5px;
            height: 35px;
            text-transform: capitalize;
            font-size: var(--common-heading);
            line-height: 12px !important;

            &:hover {
                  background-color: var(--color-base);
            }
      }

      .csvIcon {
            color: #ffffff;
            margin-right: 5px;
            font-size: 18px;
            font-weight: bold;
      }

      @media (max-width:480px) {
            .IdgButton {
                width: 150px !important;
            }
      }
      @media (max-width:376px) {
            .IdgButton {
                width: 140px !important;
            }
      }
}