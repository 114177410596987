

.order-confirmation-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .confirmation-card {
    width: 100%;
    margin: auto;
    background-color: #DACFBF;
    padding: 20px;
  }
  
  .view-order-button {
    margin: 20px;
    background-color: #25A8E0;
  }
  
  .place-order-button {
    margin: 20px;
    background-color: #25A8E0;
  }
  .confirmationAlertStyling {
    padding: 20px;
    width: 100%;
    text-align: left;
    margin: 10px;
    font-weight:600;
  }
  
  .pickup-button {
    margin: 20px;
    background-color: green;
  }
  
  .order-details-card {
    width: 100%;
    margin: auto;
  }
  
  .table-header {
    background-color: #f0f0f0;
  }

  .buttonResponsive { 
    margin: 10px !important;
    background-color: #25A8E0  !important;
    width: 310px  !important;
    height: 35px !important;
    text-transform: capitalize  !important;
    color: #FFFFFF  !important;
    cursor: pointer !important;
    
  }
  @media (max-width: 760px) {
    .buttonResponsive {
          width: 100%  !important;
    }
}

.buttonResponsive1{
  margin: 10px !important;
  background-Color: var(--color-green) !important;
  width: 310px  !important;
  height:35px;
  text-transform: capitalize  !important;
  cursor: pointer !important;
  
}
@media (max-width: 760px) {
  .buttonResponsive1 {
        width: 100%  !important;
  }

}

.customBox2 {
  text-align: left;
  margin-bottom: 30px;
}
.scopedClassName {
  background: var(--color-whitesmoke-100);
  height: 50px;
  padding: 10px !important;
  color: var(--color-dimmergray);
  font-weight: 400;
  display: flex;
}
.scopedClassNameForData {
  background: var(--color-whitesmoke-100);
  height: 50px;
  padding: 10px !important;
  color: var(--color-dimmergray);
  font-weight: 400;
  display: flex;
}

.scopedClassHeading {
  color: var(--color-black);
  height: 50px !important;
  font-weight: 600 !important;
  font-size: var(--secondary-heading) !important;
}
.scopedClassNameLine{
  font-size: 16px;
  color: #808080 !important;
}
.placeAnotherOrder {
  margin: 25px 0px !important;
  background-color: #25A8E0 !important;
  padding: 0px 25px !important;
  height: 50px !important;
  text-transform: capitalize !important;
  border: none !important;
  color: var(--bg-white) !important;
  font-size: 16px !important;
  cursor: pointer !important;
}

@media (max-width: 760px) {
  .placeAnotherOrder {
    width: 100% !important;
  }
  .scopedClassNameForData{
    height: 70px !important;
  }
}

.alertTitle{
  font-weight: 600; 
  font-size: var(--secondary-heading);
}

.textDecorationUnderline{
  text-decoration: underline;
}

.lineHeight{
  line-height: 8px
}

.orderIdLink{
  font-size:var(--secondary-heading);
  color:var(--color-base);
  text-decoration:underline;
  cursor:pointer;
}

.OrderSubmittedSuccessfully{
  font-size: var(--main-heading);
}

.OrderSubmittedSuccessfullySection{
  text-align: center;
  margin: 10px 0px;
}