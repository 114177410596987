.commonContainer {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    font-weight: bold;
    font-size: var(--secondary-heading);
}
.expirationLabel{
    width: 35%;
}

.handleCancel {
    padding: 0 !important;
    color: var(--color-black) !important;
}

.customButtonBase {
    width: 100px;
    height: 38px;
    border: 0;
    font-size: var(--common-content);
    text-transform: capitalize !important;

    &:hover {
        background-color: var(--color-1);
    }
}

.customYesButton {
    @extend .customButtonBase;
    background-color: var(--color-blue) !important;

}

.customCancelButton {
    @extend .customButtonBase;
    background-color: var(--color-darkgray-500) !important;
}

.customButtonStyleReset {
    background-color: var(--color-darkgray-500) !important;
    color: var(--bg-white) !important;
    text-transform: none !important;
    margin-right: 20px !important;
}

.customButtonStyleReset:hover {
    background-color: var(--color-darkgray-500) !important;
}

.customButtonStyleSearch {
    background-color: var(--color-base) !important;
    color: white !important;
    text-transform: none !important;
}

.customButtonStyleSearch:hover {
    background-color: var(--color-base) !important;
}

.commonDialogTitle {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: rgba(242, 242, 242, 1);
}

.customMarginTop {
    margin-top: 20px;
}

.DisabledButton {
    @extend .customButtonBase;
    color: #FFF !important;
    background-color: var(--color-skyblue) !important;
}

.DisabledButton:hover {
    @extend .customButtonBase;
    background-color: #979797;
}
@media screen and (max-width:360px) {
    .expirationLabel{
        width: 100%;
    }
    .customButton{
        margin-top: 10px !important;
    }
}