.etaMain{
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-bottom: 3px;
}
.timeIcon{
    font-size: var(--icon-size-time);
}
.etaText{
    margin-left: 5px;
    font-size: var(--common-heading);
    font-weight: 600;
}
@media screen and (max-width: 480px){
    .etaMain{
       margin: 3px 0px 0px 0px
    }    
}
@media screen and (max-width: 1200px){
    .etaMain{
        margin: 0px;
    }
}
