.EquipmentQuantityUtilization {
    .mainHeadingText {
        font-size: var(--main-heading);
        font-weight: var(--fontWeight600);
      }
      .pagination {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        font-size: var(--common-heading);
        margin-top: 30px;
      }
}