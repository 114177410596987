  .fontSizeNote {
    font-size: var(--common-heading);
    margin-top: 0px;
    color: var(--color-dimmergray);
    font-weight: 600 !important;
  }

  .profileImage {
    border-radius: 50%;
    width: 50px;
    margin: 7px 0px;
  }
  
  .customDiv {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }  

  .addNoteBtn {
    margin-bottom: 50px;
  }

  .customBox2 {
    text-align: left;
  }

  .cancelButtonStyle {
    background-color: var(--color-darkgray-500) !important;
    color: var(--bg-white) !important;
    text-transform: none !important;
    margin-right: 20px !important;
  }
  
  .cancelButtonStyle:hover {
    background-color: var(--color-darkgray-500) !important;
  }
  .saveNoteButtonStyle {
    background-color: var(--color-base) !important;
    color: white !important;
    text-transform: none !important;
  }
  
  .saveNoteButtonStyle:hover {
    background-color: var(--color-base) !important;
  }
  .disabledButton {
    background-color: var(--color-skyblue) !important;
  }
  
  .noteClassName {
    background: var(--color-whitesmoke-100);
    height: 50px;
    font-family: var(--base-text);
    color: var(--color-dimgray);
    font-weight: 400;
    display: flex;
    align-items: "center"
  }
  
  .noteClassHeading {
    font-family: var(--base-text);
    margin-top: 10px !important;
    margin-left: 15px !important;
    font-weight: 600 !important;
    font-size: 20px !important;
  }

  .name{
    font-size: var(--secondary-heading);
  }
  
  .date{
    font-size: var(--common-heading);
    color: var(--color-dimgray);
    font-weight: 600;
  }
  
  @media (max-width: 768px) {
    .name{
      font-size: var(--common-heading);
    }
    .date{
      margin-left: '0' 
    }
  }
  .scopedClassNameLine {
    display: flex;
    align-items: center;
    margin-top: 1;
    font-weight: 600 !important;
  
    & span {
      color: var(--color-black);
    }
  
    & span:first-of-type {
      color: var(--color-dimgray);
    }
  
    font-family: var(--base-text);
  }
  
  .customSizeImage2 {
    height: 27px;
    min-width: 27px;
  }
  
  .customButton {
    background-color: var(--color-base) !important;
    box-shadow: none;
    text-transform: none;
  }
  
  .customButton:hover {
    background-color: var(--color-base) !important;
  }
 
  .customCardContent {
    padding: 14px ;
    
  }
  
