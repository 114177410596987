.margin_top_bottom_10px {
      margin: 10px 0px !important;
}
.customButton {
      background-color: var(--color-base) !important;
      border-radius: 5px;
      height: 35px;
      text-transform: capitalize !important;
      font-size: var(--common-heading);

      &:hover {
            background-color: var(--color-base) !important;
      }
}
.documentMargin{
      margin-top: 20px;
}
.cardContainer {
      padding: 0 !important;
      border-radius: 8px !important;
}
.customBox2 {
      text-align: left;
      margin-top: 20px;
  }  
.mainHeadingText {
      font-size: var(--secondary-heading);
      font-weight: 600;
      margin-top: 35px;
      margin-bottom: 15px;
}
.scopedClassNameLine {
      align-items: center;
      margin-top: 1;
      font-weight: 600 !important;
      font-size: var(--common-heading) !important;
      margin-bottom: 5px !important;
}
.tooltipFontSize{
      font-size: 13px;
}
.buttonContainer {
      display: flex !important;
      flex-direction: column !important;
      margin-top: auto !important;
      margin-bottom: 10px !important;
      justify-content: flex-end !important;
} 
.chipContainer {
      display: flex !important;
      justify-content: flex-end !important;
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
}  
.customBadgeChip {
      padding: 0px 40px !important;
      border-radius: 0px 0px 0px 6px !important;
      font-size: var(--badges-font-size) !important;
      font-weight: 800 !important;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.4) !important;
      color: white !important;
      width: 210px !important
} 
.relativeContainer {
      position: relative !important;
      padding-bottom: 16px !important;
}
.customText4 {
      color: var(--color-dimgray);
      font-size: var(--common-heading) !important;
      font-weight: 600 !important;
}
.customTextBold1 {
      color: var(--color-dimmergray);
      font-size: var(--common-heading);
      font-weight: 600 !important;
      margin-left: 5px;
}
.customTextBold {
      color: var(--color-dimmergray);
      font-size: 20px;
      font-weight: 600 !important;
}
.scopedClassHeading {
      color: var(--color-black);
      font-weight: 600 !important;
      font-size: var(--secondary-heading) !important;
}
.customLink {
      margin-left: 8px !important;
      color: var(--color-base) !important;
      text-decoration: underline !important;
      cursor: pointer;
}
.mobileView {
      display: flex;
      flex-direction: row !important;
      align-items: flex-end;
      margin-left: 0px;
      margin-top: 15px;
}
.receiptButton {
      min-width: 47px !important;
      height: 44px !important;
      border-radius: 10px !important;
      padding: 5px !important;
      background-color: var(--color-base) !important;
      color: var(--bg-white) !important;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;

      &:hover {
            box-shadow:
                  rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
      }
}
.blueButton {
      min-width: 48px !important;
      height: 48px !important;
      border-radius: 10px !important;
      margin-left: 8px !important;
      background-color: var(--color-base) !important;
      color: var(--bg-white) !important;
      cursor: pointer;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;

      &:hover {
            box-shadow:
                  rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
      }
}
.greenButton {
      min-width: 48px !important;
      height: 48px !important;
      border-radius: 10px !important;
      background-color: var(--color-green) !important;
      color: var(--bg-white) !important;
      cursor: pointer;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;

      &:hover {
            box-shadow:
                  rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
      }
}
.redButton {
      min-width: 48px !important;
      height: 48px !important;
      border-radius: 10px !important;
      margin-left: 8px !important;
      background-color: var(--color-red) !important;
      color: var(--bg-white) !important;
      cursor: pointer;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;

      &:hover {
            box-shadow:
                  rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
      }
}
.receiptIcon {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
}
.customSizeImage {
      height: 40px !important;
      min-width: 27px !important;
}
.buttonStylingOnTrue {
      background-color: var(--color-lightgrey) !important;
      border-color: var(--color-dimgray) !important;
      color: var(--color-black) !important;
      text-transform: none !important;
      font-weight: 600 !important;
      border-radius: 5px !important;
      font-size: var(--common-heading) !important;
      height: 35px !important;
}
.customTextWrap{
      color: var(--color-black);
      font-size: 20px;
      font-weight: 600 !important;
      display: block;
      word-wrap: break-word; 
      width: 90%;
}
.buttonStylingOnFalse {
      background-color: var(--chrome-light-background) !important;
      border-color: var(--color-dimgray) !important;
      color: var(--color-black) !important;
      text-transform: none !important;
      font-weight: 600 !important;
      border-radius: 5px !important;
      font-size: var(--common-heading) !important;
      height: 35px !important;
}
.paddingContainer{
      padding-left: 0 !important;
      padding-right: 0 !important;
      max-width: 100% !important;
}
.buttonStyling {
      display: flex;
      justify-content: flex-end;
}
.module {
      margin-top: 40px;
      border: 1px solid var(--color-darkgray-300);
      border-radius: 4px;
      overflow: auto;
}
.dialogDescription {
      text-align: left;
      margin: 0px;
      font-size: var(--content-font-size-16px);
      font-weight: 400;
}
.dialogBold {
      font-weight: bold !important;
      word-wrap: break-word;
}
.iconContainer {
      flex-direction: row;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      font-weight: bold;
      font-size: var(--secondary-heading);
}
.sessionTimeoutButton {
      background-color: #25A8E0 !important;
      min-width: 150px !important;
      min-height: 50px !important;
      margin-right: 15px !important;
      margin-bottom: 5px !important;
}
.customButtonBase {
      width: 100px;
      height: 38px;
      border: 0;
      font-size: var(--common-content);
      text-transform: capitalize !important;

      &:hover {
            background-color: var(--color-1);
      }
}
.customYesButton {
      @extend .customButtonBase;
      font-size: 14px !important;
      background-color: var(--color-red) !important;
}
.customCancelButton {
      @extend .customButtonBase;
      font-size: 14px !important;
      background-color: var(--color-darkgray-500) !important;
}
.commonDialogTitle {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      background: rgba(242, 242, 242, 1);
}
.commonFexContainer {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      font-weight: bold;
      font-size: var(--secondary-heading);
}
@media screen and (max-width: 480px) {
      .buttonStyling {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
      }
}
@media screen and (max-width: 360px){
      .fieldAlignment{
            margin-top: 15px;
      } 
}
@media screen and (max-width: 786px) {
      .customTextWrap {
            margin-top: 15px !important;
      }
}