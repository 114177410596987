.tabContainer {
    margin-top: var(--gap-20px);

    .selectedContainer {
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .selectedContainer p {
        margin: 0;
    }
}

.menuItem {
    font-weight: var(--fontWeight600);
}

.activeItem {
    color: var(--color-base) !important;
}

.activeItem .MuiSvgIcon-root {
    color: var(--color-base) !important;

}

.activeItemSpan {
    background-color: var(--color-base) !important;
    margin-right: 10px !important;
}

.firstItemSelected {
    border-top-left-radius: 1.5px;
}

.lastItemSelected {
    border-bottom-left-radius: 1.5px;
}

.itemSpan {
    height: 48px !important;
    min-width: 20px !important;
}