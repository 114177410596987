.dialogDescription {
    text-align: left;
    margin: 0px;
    font-size: var(--content-font-size-16px);
    font-weight: bold;
    padding-bottom: 20px;
}

.customCloseIconContainer {
    position: absolute !important;
      top: 0 !important;
      right: 0 !important;
      color: black;
      }

.sessionTimeoutButton {
    background-color: #25A8E0 !important;
    min-width: 150px !important;
    min-height: 50px !important;
    margin-right: 15px !important;
    margin-bottom: 5px !important;
}

.handleCancel{
    padding-right: 0px !important;
}

.customButtonBase {
    width: 100px;
    height: 38px;
    border: 0;
    font-size: var(--common-content);
    text-transform: capitalize !important;

    &:hover {
          background-color: var(--color-1);
    }
}

.customYesButton {
    @extend .customButtonBase;
    background-color: var(--color-red) !important;

}
.customCancelButton {
  @extend .customButtonBase;
  background-color: var(--color-darkgray-500) !important;
}
.commonDialogTitleCancelOrder {
padding-top: 0 !important;
padding-bottom: 0 !important;
background: rgba(242, 242, 242, 1);
}

.commonContainer {
flex-grow: 1;
display: flex;
justify-content: space-between;
align-items: center;
height: 55px;
font-weight: bold;
font-size: var(--secondary-heading);
}