.SupplyDetails {
    .gridAlignment {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .orderMargin {
        display: flex;
        padding-bottom: 5px;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 8px;
    }

    .loaderMargin {
        margin-top: 50px;
    }

    .chipStyling {
        display: flex;
        padding-bottom: 5px;
        font-size: 25px;
        font-weight: 300;
    }

    .customChip {
        color: var(--bg-white);
        border-radius: 6px;
        font-weight: 800;
        font-size: 13px;
        margin-bottom: 8px;
    }

    .customLink {
        font-size: 24px;
        font-weight: 600;
        color: var(--color-base);
        display: block;
        text-decoration: none;
        cursor: pointer;
    }
    
    .customLinkTrackingNumber {
        font-size: var(--content-font-size-16px);
        font-weight: 600;
        color: var(--color-base);
        text-decoration: underline;
        cursor: pointer;
    }

    .scopedClassNameLine {
        display: flex;
        align-items: center;
        margin-top: 1;
        font-weight: 600;
        font-size: var(--common-heading);
        margin-bottom: 5px;
    }

    .paddingBottom {
        padding-bottom: 5px;
    }

    .customChipProvider {
        border-radius: 10px;
        background-color: var(--color-grey);
        margin: 0px 0px 0px 5px;
        color: var(--bg-white);
    }

    .customContactChip {
        border-radius: 10px;
        background-color: var(--color-green);
        margin: 0px 0px 0px 5px;
        color: var(--bg-white);
    }

    .paddingLeft {
        padding-left: 2px;
    }

    .notesStyling {
        margin-bottom: 5px;
        display: flex;
        flex-wrap: wrap;
    }

    .customContainer {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0;
        display: flex;
    }

    .cardContentPadding {
        padding: 0px 0px 1px 16px;
    }

    .cardMarginBottom {
        margin-bottom: 8px;
    }

    .clipAlignment {
        display: flex;
        flex-wrap: wrap;
        font-size: var(--common-heading) !important;
        align-items: center;
    }

    .nurseAlignment {
        margin-right: 4px;
        margin-bottom: 0px;
    }

    .patientNurse {
        display: flex;
        align-items: center;
    }

    .patientContact {
        display: flex;
        flex-direction: column;
        width: "100%";
    }
    .displayFlexViewOtherLink{
        display: flex;
    }
    .idsLevel{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
    }
    .branchText {
        color: var(--color-dimmergray);
        font-size: var(--secondary-heading);
        font-weight: 800;
        margin-bottom: 10px;
      }
      .myCustomClass2 {
        margin: 10px 0px 10px 10px;
        border-radius: 6px ;
        font-size:  var(--badges-font-size);
        font-weight: 800;
        margin-left: 13px;
        @media (max-width: 480px) {
          margin-left: 0px;
        }
      }

    @media (max-width: 480px) {
        .gridAlignment {
            display: flex;
            justify-content: flex-start;
            margin-top: 5px;
        }
    }
}