  .customBox2 {
    text-align: left;
    margin-bottom: 30px;
  }

  .scopedClassHeading {
    color: var(--color-black);
    height: 50px !important;
    font-weight: 600 !important;
    font-size: var(--secondary-heading) !important;
  }
  .scopedClassName {
    background: var(--color-whitesmoke-100);
    height: 50px;
    padding: 10px !important;
    color: var(--color-dimgray);
    font-weight: 400;
    display: flex;
  }
  .flexContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
  }
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 10px;
    width: 100%;
  }
  .imageContainer {
    max-width: 140px;
    height: 140px;
    cursor: pointer;
    object-fit: contain;
    margin-right: 15px;
    margin-bottom: 5px;
  }
  .customNotesStyle {
    display: flex ;
    margin-top: 9px ;
    font-size: var(--content-font-size-16px);
  }
  .customText4 {
    color: var(--color-dimgray);
    font-size: var(--common-heading) !important;
    font-weight: 600 !important;
  }
  .wordWrapContact {
    word-wrap: break-word;
    max-width: 100%;
    font-size: var(--common-heading);
    color: var(--color-dimmergray);
    font-weight: 600;
    margin-left: 5px;
  }
  .flexContainerProofOfDelivery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .flexCenter {
    display: flex !important;
    align-items: center !important;
    font-size: var(--content-font-size-16px);
  }
  .textStyle {
    color: var(--color-dimgray);
    font-family: var(--base-text);
    font-weight: 700;
  }
  
.greenBoldText {
    color: var(--color-green) !important;
    font-weight: bold !important;
    margin-left: 8px !important;
  }
  .greenTextWithMargin {
    color: var(--color-green);
    margin-left: 5px;
    margin-top: 5px;
  }
  .fontSizeLarge {
    font-size: var(--secondary-heading);
  }
  .redBoldText {
    color: var(--color-red) !important;
    font-weight: bold !important;
    margin-left: 8px !important;
  }
  
  .textStyleProofOfDelivery {
    font-size: var(--secondary-heading);
    margin-top: 3px;
  }
  .marginLeftAuto {
    margin-left: auto;
  } 
  .customButtonStyle {
    background-color: var(--color-base) !important;
    color: white !important;
    text-transform: none !important;
  }
  
  .customButtonStyle:hover {
    background-color: var(--color-base) !important;
  }
  .iconDimensions {
    width: 26px ;
    height: 26px;
    padding-bottom: 3px ;
    padding-right: 3px;
  }
  @media (max-width: 500px) {
    .flexButton {
      flex-basis: 100%;
    }
  }