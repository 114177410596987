.formCardContainer {
      padding: 16px;
}

.formAlertDiv .formAlert {
      border: 1px solid var(--color-silver-100);
      color: var(--color-dimmergray);
      border-radius: var(--br-8px);
      font-size: var(--common-heading);
      margin: 30px 0;
}

.alertIcon {
      color: var(--color-black);
}

.infoIcon {
      color: var(--color-base);
      vertical-align: middle;
      cursor: pointer;
}

.submitButton {
      background-color: var(--color-base);
}

.caneclButton {
      background-color: var(--color-darkgray-500) !important;
}

.cardMarginBottom {
      margin-bottom: 16px;
}

.formCardMain {
      border: 1px solid var(--color-silver-100);
      border-radius: var(--br-8px);
      text-align: left;
      margin-bottom: 30px;
}

.formBoxHeader {
      background: var(--color-whitesmoke-100);
      height: 50px;
      padding: 10px 12px;
      border-radius: var(--br-8px) var(--br-8px) 0 0;
}

.formHeading {
      height: 50px;
      font-weight: 600;
      font-size: var(--secondary-heading);
}

.formLabel {
      font-weight: 600;
      font-size: var(--common-heading);
      margin-bottom: 6px;
}

.textareaFormLabel{
      font-weight: 600;
      font-size: var(--common-heading);
      margin-bottom: 10px;
}

.formInputBox {
      width: 100%;
      border-radius: var(--br-8px);
}

.formDisableField{
      background-color: var(--color-disabled);
}

.formInputBox input {
      padding: 8.5px 14px;
}

.formTextArea {
      &:focus {
            border: none;
            outline: 2px solid #1e80c2;
      }
    min-width: 100%;
    max-width: 100%;
    padding: 5px 10px;
    border: 1px solid var(--color-darkgray-100);
    border-radius: var(--br-8px);
    font-size: var(--common-heading);
}

.formTextAreaError {
      &:focus {
            border: none;
            outline: 1px solid var( --color-red);
      }
    min-width: 100%;
    max-width: 100%;
    padding: 5px 10px;
    border: 1px solid var(--color-darkgray-100);
    border-radius: var(--br-8px);
    font-size: var(--common-heading);
}

.formTextArea:hover {
      border: none;
      outline: 1px solid #000;
}

.formSubmitBtnBox{
      margin: 30px 0;
      float: right;
}

.formBox{
      border: 1px solid var(--color-silver-100);
      border-radius: var(--br-8px);
      margin: 5px 0 20px 0;
}

.submitBtnDiv{
      margin: 30px 0;
      float: right;
}

.submitBtnDiv .submitButton{
      &:hover {
            background-color: var(--color-base);
      }
      background-color: var(--color-base);
      color: var(--bg-white);
      text-transform: none;
      height: 42px;
      width: 100px;
      font-size: var(--common-heading);
}

.submitBtnDiv .cancelButton {
            &:hover {
            background-color: var(--color-darkgray-500);
      }
      background-color: var(--color-darkgray-500);
      text-transform: none;
      height: 42px;
      width: 100px;
      font-size: var(--common-heading);
      margin-right: 10px;
  }



.radioBtnBox{
      margin: 0 20px;
      display: flex;
      align-items: center;
}

.formRadioBtn{
      margin: 0 50px;
}

.FormAutoComplete {
    padding: var(--padding-0px);
}

.formDetails {
    margin: 0 0 5px;
}

.formDetails .formDetailsHead {
    color: var(--color-dimgray);
    font-size: var(--content-font-size-16px);
    font-weight: 600;
}

.formDetails .formDetailsValue {
    margin-left: 5px;
    color: var(--color-dimmergray);
    font-weight: 600;
    font-size: var(--content-font-size-16px);
}

.checkBoxSelectText {
      color: var(--color-black);
}

.linkColor {
      text-decoration: none;
      color: var(--color-base);
}

.downloadBtnDiv {
      margin: 20px auto;
      text-align: end;
}

.customSizeImage {
      height: 18px !important;
      min-width: 17px !important;
  }

  .formCheckBoxContainer {
      display: flex;
      align-items: center;
}

.formCheckBoxContainer .formCheckbox {
      padding: 0 var(--padding-8px) var(--padding-5px) 0;
}

.flexBox{
      display: flex;
      flex-direction: row;
      align-items: center;
}

.flexBoxColumn{
      display: flex;
      flex-direction: column;
}

.marginRight{
      margin-right: 35px;
}

.labelContainer{
      display: flex;
      flex-direction: row;
      align-items: center;
}

@media screen and (max-width:768px) {
      .labelContainer{
            display: flex;
            flex-direction: column;
            align-items:flex-start;
      }
    }