.locationDialog .locationDialogTitle {
    padding-top: 0 ;
    padding-bottom: 0 ;
    background: rgba(242, 242, 242, 1);
}

.locationTitle {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    font-weight: bold;
    font-size: var(--secondary-heading);
}

.locationTitle .closePopup {
    padding-right: 0px;
    color: var(--color-black);
}

.etaStatus {
    display: flex;
    align-items: center;
    margin: 8px 4px;
}

.infoWindowContainer p{
    margin: 5px;
}
