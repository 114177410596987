.margin_top_bottom_10px {
      margin: 10px 0px !important;
}

.mainHeadingText {
      font-size: var(--main-heading);
      font-weight: 600;
}
.mainContainer2 {
      border: 1px solid rgba(0, 0, 0, 0.12);
      overflow: hidden;
      border-radius: 4px;
      border-bottom: none;
}

.pd_r_25px {
      padding-right: 25px !important;
}
.reportIcon{
      line-Height: 16px !important;
      font-Size: var(--icons-size) !important;
      margin-Left: 8px !important
}

.customDiv {
      cursor: pointer !important;
}

.mainHeading {
      display: flex !important;
      justify-content: flex-end !important;
      align-items: flex-end;
}

.alignmentRight {
      text-align: right !important;
}

.searchContainer1Style {
      position: "relative";
}

.toggleButton {
      width: 150px;
      height: 36px;
      border-radius: 10px;
      border: 1px solid var(--color-darkgray-100);
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: var(--color-black) !important;
}

.toggleButton Button {
      color: var(--color-darkgray-100);
      text-transform: capitalize !important;
}

.customButton {
      background-color: var(--color-base) !important;
      border-radius: 5px;
      height: 35px;
      text-transform: capitalize !important;
      font-size: var(--common-heading);

      &:hover {
            background-color: var(--color-base) !important;
      }
}

.MuiAccordionSummary-content {
      margin: 0px !important;
}

.customAccordion {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
      border-radius: 30px !important;
      box-shadow: none !important;
}

.customAccordion .MuiAccordionSummary-content {
      margin: 0;
}

.customAccordionSummary {
      z-index: 1;
      position: relative;
      cursor: default;
}

.customAccordionDetails {
      z-index: 1;
      position: absolute;
      width: 100%;
      background-color: var(--bg-white);
      border-radius: 10px;
      box-shadow: 0px 1px 5px #888888;
}

.customMargin1 {
      margin-bottom: 10px;
      margin-top: 20px;
}

.customMarginBottom {
      margin-bottom: 20px;
}
.myCustomClass1 {
      color: var(--color-dimgray) !important;
      margin-left: 5px;
    }

.customButtonStyleReset {
      background-color: var(--color-darkgray-500) !important;
      color: var(--bg-white) !important;
      text-transform: none !important;
      margin-right: 10px !important;
}

.customButtonStyleReset:hover {
      background-color: var(--color-darkgray-500) !important;
}

.customButtonStyleSearch {
      background-color: var(--color-base) !important;
      color: white !important;
      text-transform: none !important;
}

.customButtonStyleSearch:hover {
      background-color: var(--color-base) !important;
}

.disabledButton {
      background-color: var(--color-skyblue) !important;
}

@media (max-width: 1200px) {
      .alignmentRight {
            margin-left: auto !important;
      }

      .mainHeading {
            display: flex !important;
            justify-content: flex-start !important;
      }
}

@media (max-width: 760px) {
      .mainHeading {
            display: flex !important;
            margin-top: 10px;
            justify-content: flex-start !important;
      }
}

@media (max-width: 480px) {
      .toggleButton {
            margin-left: auto !important;
      }
      .orderStatusButton {
            flex-direction: column !important;
            align-items:flex-start !important;
            margin:4px 0px !important;
        }
    
}


// patients-orders CSS
.mainDiv {
      margin-top: 2.5rem !important;
      margin-bottom: 1rem !important;
      width: 100% !important;
}
.mainDivExport {
      margin-top: 2.5rem !important;
      width: 100% !important;
}

.iconContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: -68px;
}

.marginClass {
      margin: 0
}

.statusButton {
      margin: 5px 5px 5px 0px;
      border-radius: 6px !important;
}

.aLink:link,
.aLink:visited {
      color: #25A8E0;
      text-decoration: underline;
      font-size: 1rem;
      padding-left: 5px;
      letter-spacing: 0em;
      text-align: left;
      font-weight: 700 !important;
}

.buttonText {
      text-transform: none !important;
      font-size: 0.8rem;
      box-shadow: none;
      color: #ffffff;
      border-radius: 5px;
}

.buttonText .css-1d6wzja-MuiButton-startIcon>*:nth-of-type(1) {
      font-size: 24px !important;
}

.backgroundColorGreen {
      color: rgb(97, 150, 51) !important;
}

.backgroundColorBlue {
      color: #25A8E0;
}
.marginLeftIcon{
      margin-left: 6px;
}

.deviveryStatusText {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 25px;
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--icons-text-color);
}
.deliveryStatStatusText {
      font-weight: 600;
      font-size: 1rem;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--icons-text-color);
      margin-left: 2px !important;
}

.scopedClassColorText2 {
      margin-left: 5px;
      font-weight: 600 !important;
      color: var(--color-dimmergray) !important;
}

.customSizeImage {
      height: var(--icons-size) !important;
      min-width: 27px !important;
}

.customSizeImage1 {
      height: 27px;
      min-width: 27px;
}


.iconSize{
      font-size: var(--icons-size) !important;
}

.toggleButtonFilter {
      width: 100%;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 20px;
      color: var(--color-black) !important;
    }
    
    .toggleButtonFilter Button {
      color: var(--color-base);
      border: 2px solid var(--color-base);
      border-radius: 5px;
      text-transform: capitalize !important;
    }
    
    .commonContentFilter {
      font-size: var(--common-content);
    }

.scopedClassNameLine2 {
      align-items: center;
      margin-top: 1;
      font-weight: 600 !important;

      & span:first-of-type {
            color: var(--color-dimgray);
      }

      font-family: var(--base-text);
}

.customDiv .orderDataCard {
      text-align: left;
      border-radius: 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      border-right: none; 
      border-top: none;
      border-left: none;
}
.customDiv .boxContainer {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between; 
    }
    
@media (max-width: 600px) {
  .customDiv .boxContainer {
      flex-direction: column;
      justify-content: flex-start; 
}
}
    
.customDiv .typographyContainer {
      display: flex;
      padding-bottom: 5px;
      font-size: 24px;
      font-weight: 600;
    }
    
.customDiv .stackContainer {
      display: flex;
      margin-bottom: 10px;
      align-items: flex-start;
      justify-content: flex-start;
    }
    
@media (max-width: 600px) {
  .customDiv .stackContainer {
      justify-content: space-between; 
}
}
    
@media (max-width: 768px) {
      .iconContainer {
            margin-top: 0px;
            justify-content: flex-start;
      }
}
.orderStatusButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin: 8px 0px;
  }
  .orderChipWithEtaAndMap {
      display: flex;
      justify-content: space-between;
  }
  .divRelativePosition{
      position: relative;
  }