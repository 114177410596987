.PreAuthorization {
    .preAuthBorder {
        border-radius: 0px;
    }
    .preAuthCard {
        @extend .preAuthBorder;
        margin: 31px 0px 0px 0px;
        box-shadow: none;
    }
    .preAuthMain {
        border: 1px solid var(--color-darkgray-300);
        border-radius: 4px;
        overflow: auto;
    }
    .preAuthWithoutBorder{
        @extend .preAuthBorder;
        border: none;
    }
    .preAuthSpan {
        font-size: 24px;
        font-weight: 600;
    }
    .preAuthWithNoMargin {
        margin:0;
    }
    .preAuthListDiv {
        background: var(--color-base);
        padding: 5px 5px 0px; 
        border-radius: 10px; 
        display: inline-block;
        cursor: pointer;
        width: 48px;
        height: 48px; 
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
        &:hover {
          box-shadow:
            rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
        }
        .assignmentIcon {
            color: var(--bg-white);
            font-size: 20px; 
            height: 40px;
            min-width: 27px;
        }
    }
    .content{
    font-weight: 600;
    font-size: var(--common-heading);
    color: var(--color-dimgray);
    }
  .scopedClassPreAuth {
    @extend .content;
    margin-left: 5px;
    }
    .OrdersPreAuth {
      box-shadow: none;
    } 
    .buttonParentDiv{
      text-align: right;
    }
    @media (max-width:760px){
      .buttonParentDiv{
        text-align: left;
        margin-bottom: 10px ;
      }
    }
}