.section {
    border: 1px solid var(--color-silver-100);
    border-radius: var(--br-8px);
    padding: 20px;
    background: var(--color-whitesmoke-100);

    h3 {
        margin: 0;
        font-size: var(--secondary-heading);
    }

    p {
        margin: 0;
    }
}

.cardItemBox {
    border: 1px solid var(--color-silver-100);
    border-bottom: none;
    padding: 10px;
    background: var(--bg-white);
}

.cardItemBox:last-child {
    border-radius: 0 0 var(--br-2px) var(--br-2px);
    border-bottom-left-radius: var(--br-2px) !important;
    border-bottom-right-radius: var(--br-2px) !important;
    border-bottom: 1px solid var(--color-silver-100);
}

.cardItemBox:first-of-type {
    border-radius: var(--br-2px) var(--br-2px) 0 0;
}

.scopedClassNameLine2 {
    margin-top: 1;
    font-weight: 600 !important;
    color: var(--color-dimgray);
    font-family: var(--base-text);
}

.scopedClassColorText2 {
    font-weight: 600 !important;
    color: var(--color-dimmergray) !important;
}

.chipMargin {
    margin: 5px;
    background-color: rgba(151, 151, 151, 0.4) !important;
}

.cardName {
    text-decoration: none;
    color: var(--color-base);
    cursor: pointer;
}

.cardNurseContainer {
    display: flex;
    align-items: center;
}

.itemLabelContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.statusChip {
    margin-top: 8px;
    color: var(--bg-white) !important;
    border-radius: 6px !important;
    font-size: var(--badges-font-size) !important;
    font-weight: 800 !important;
}

@media screen and (max-width: 480px) {
    .cardNurseContainer {
        flex-direction: column;
        align-items: flex-start;
    }

    .chipMargin {
        margin: 5px 5px 5px 0;
    }
}