.blueButton {
    min-width: 48px !important;
    height: 48px !important;
    border-radius: 10px !important;
    margin-left: 8px !important;
    background-color: var(--color-grey) !important;
    color: var(--bg-white) !important;
    cursor: pointer;
    border: none; 
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
    &:hover {
      box-shadow:
        rgba(0.4, 0, 0.2, 0.3) 0px 3px 1px -2px, rgba(0.4, 0, 0.2, 0.3) 0px 2px 2px 0px, rgba(0.4, 0, 0.2, 0.3) 0px 1px 5px 0px !important;
    }
  }

.tooltipFontSize {
    font-size: 13px;
}