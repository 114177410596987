// .PriorityIcon {}
.deliveryStatusImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.priorityMarginBottom {
  margin-bottom: 2px;
}
.customSizeImage2 {
    font-size: var(--icons-size) !important;
  }
  .deliveryStatusText {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--icons-text-color) !important;
  }
  .backgroundColorBlue {
    color: var(--color-base);
  }
  .backgroundColorRed {
    color: var(--color-red);
  }
  .backgroundColorYellow {
    color: var(--color-yellow);
  }
  .backgroundColorGreen {
    color: var(--color-green);
  }
  .lineHeight{
    line-height: 20px; 
  }
  .lineHeightDropshipIcon{
    line-height: 26.5px; 
  }
  .marginRight6px{
    margin-right: 6px;
  }
  .statIcon{
    margin-right: 1px; 
  }
  .routineIconMarginBottom{
    margin-bottom: 5px;
  }
  .urgentIcon{
    color: var(--color-orange);
    line-height: 16px;
    margin-left: 1px;
  }
  @media (max-width: 768px) { 
    .deliveryStatusText{
        margin-left: 0px
      }
  }
