.OrderType {
    .deliveryStatusText {
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--icons-text-color);
        font-weight: 600;
        font-size: var(--content-font-size-16px);
        line-height: 25px;
        
    }

    .mixedOrderValue {
        margin-top: -6px;
    }

    .mixedOrderIcon {
        margin-left: 2px;
    }

    .iconSize{
      font-size: var(--icons-size);
    }
    .serviceIconLineHeight {
        line-height: 8px;
    }
    .marginLeftSix{
      margin-left: 6px;
    }
    .pickUpWidth{
        width: 53px;
    }
    .pickUpAllWidth{
        width: 82px;
    }
    .deliveryWithService {
        display: flex;
        align-items: center;
        .serviceIcon{
            @extend .serviceIconLineHeight;
            margin-left: 16px;  
        }
    }
}